import { Heading, Text } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import BaseButton from 'widgets/BaseButton'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;

  img {
    width: 250px;
    margin-bottom: 24px;
  }
`

const NotFound = () => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Page>
      <StyledNotFound>
        {/* <LogoIcon width="120px" mb="2rem" /> */}
        <img src="/images/logo.png" alt="logo" />
        <Heading scale="xxl">404</Heading>
        <Text mb="16px">{t('Oops, page not found.')}</Text>
        <BaseButton onClick={() => router.push('/home')}>{t('Back Home')}</BaseButton>
      </StyledNotFound>
    </Page>
  )
}

export default NotFound
